<div class="authentication-reset">
  <div class="container-fluid">

    <div class="card">
      <div class="card-body">

        <app-authentication-header></app-authentication-header>

        <form *ngIf="reset==false" [formGroup]="mainForm" (ngSubmit)="doSubmit()">
          <div class="form-group">
            <label class="required" for="frm-password">Nuova password</label>
            <input type="password" formControlName="password" id="frm-password" class="form-control" />
          </div>
          <div class="form-group">
            <label class="required" for="frm-password_confirmation">Ripeti password</label>
            <input type="password" formControlName="password_confirmation" id="frm-password_confirmation"
              class="form-control" />
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" [disabled]="mainForm.invalid || loading">reimposta</button>
          </div>
        </form>

        <div class="small mt-4">
          <a [routerLink]="['/login']">Effettua il login.</a>
        </div>

        <div class="small mt-1">
          <a [routerLink]="['/recover']">Password dimenticata? Recuperala qui.</a>
        </div>

        <div *ngIf="reset==true" class="alert alert-primary small mt-4" role="alert">
          Password reimpostata con successo. Effettua il login.
        </div>

        <p class="small mt-4">Form Status: {{ mainForm.status }}</p>

      </div>
    </div>

    <app-authentication-footer></app-authentication-footer>

  </div>
</div>