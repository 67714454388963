import { FormControl, FormGroup } from '@angular/forms';

export interface IValidationResult {
  [key: string]: boolean;
}

export class PasswordValidators {

  public static strong(control: FormControl): IValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const valid = hasNumber && hasUpper && hasLower;
    if (control.value && !valid) {
      return { strong: true };
    }
    return null;
  }

  public static match(group: FormGroup) {
    const change = group.controls.password_change.value;
    const password1 = group.controls.password.value;
    const password2 = group.controls.password_confirmation.value;
    //
    let _e = null;
    //
    if (change && password1 !== password2) {
      _e = { mismatch: true };
      group.controls.password_confirmation.setErrors(_e, {emitEvent: true});
    }
    return _e;
  }

}
