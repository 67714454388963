import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alert: ToastrService,
  ) { }

  private getMergedConfig(type_config: Partial<IndividualConfig>, single_config?: Partial<IndividualConfig>) {
    // https://stackoverflow.com/questions/37042602/how-to-combine-object-properties-in-typescript

    const _default = {
      closeButton: true,
      timeout: 5000,
      disableTimeOut: false
    };

    const config = single_config ? { ..._default, ...type_config, ...single_config } : { ..._default, ...type_config };

    return config;
  }

  public show(title?: string, message?: string, single_config?: Partial<IndividualConfig>, type?: string): void {
    this.alert.show(message, title, single_config, type);
  }

  public success(title?: string, message?: string, single_config?: Partial<IndividualConfig>): void {
    const config = this.getMergedConfig({}, single_config);
    this.alert.success(message, title, config);
  }

  public error(title?: string, message?: string, single_config?: Partial<IndividualConfig>): void {
    const config = this.getMergedConfig({disableTimeOut: true}, single_config);
    this.alert.error(message, title, config);
  }

  public info(title?: string, message?: string, single_config?: Partial<IndividualConfig>): void {
    const config = this.getMergedConfig({}, single_config);
    this.alert.info(message, title, config);
  }

  public warning(title?: string, message?: string, single_config?: Partial<IndividualConfig>): void {
    const config = this.getMergedConfig({disableTimeOut: true}, single_config);
    this.alert.warning(message, title, config);
  }

  public clear(toastId?: number): void {
    this.alert.clear(toastId);
  }

  public remove(toastId: number): void {
    this.alert.clear(toastId);
  }

}
