export const environment = {
  production: false,
  apiUrl: 'https://stage-api.cogemac.it/',
  token: {
    /**
     * Start refreshing token after given milliseconds before it expires
     */
    refresh_start_msec: 15 * 60 * 1000, // minutes
    /**
     * Check refresh every given milliseconds
     */
    refresh_interval_msec: 30 * 1000, // seconds
  },
};
