import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ILogoutRequest } from '../../authentication/models';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                const data: ILogoutRequest = {};
                this.authenticationService.logout(data);
                if (this.router.url !== '') {
                    this.router.navigate(['/login']);
                    // location.reload(true);
                }
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
