import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
//
import { IResetRequest } from '../../models';
import * as fromState from '../../_state/authentication.reducer';
import * as fromActions from '../../_state/authentication.actions';
import { PasswordValidators } from 'src/app/_core/validators';

@Component({
  selector: 'app-authentication-password-reset',
  templateUrl: './authentication-password-reset.component.html',
  styleUrls: ['./authentication-password-reset.component.scss']
})
export class AuthenticationPasswordResetComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public reset: boolean;
  private loading$: Subscription;
  private reset$: Subscription;

  public mainForm: FormGroup;

  private token: string;
  private email: string;

  public constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromState.State>,
    private _fb: FormBuilder,
  ) {
    /**
     * Dispatch events
     */
    this._store.dispatch(new fromActions.ResetState());

    /**
     * Form setup & validation
     */
    this.mainForm = this._fb.group({
      token: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password_change: true,
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    }, { validator: PasswordValidators.match });

    this._route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });
  }

  public ngOnInit() {
    /**
     * Store bindings
     */
    this.loading$ = this._store.select(fromState.selectLoading).subscribe((loading: boolean) => this.loading = loading);
    this.reset$ = this._store.select(fromState.selectReset).subscribe((reset: boolean) => this.reset = reset);

    this.doFormPatch();
  }

  /**
   * Getter for easy access form fields
   */
  public get f() {
    return this.mainForm.controls;
  }

  /**
   * Main Form submission
   */
  public doSubmit() {
    if (this.mainForm.invalid) {
      return false;
    }

    const data: IResetRequest = this.mainForm.value;
    this._store.dispatch(new fromActions.ResetRequest({ data }));
  }

  /**
   * Update form fields
   */
  private doFormPatch(): void {
    this.mainForm.patchValue({
      token: this.token,
      email: this.email
    });
  }

  /**
   * Destroy stuffs
   */
  public ngOnDestroy() {
    this.loading$.unsubscribe();
    this.reset$.unsubscribe();
  }

}
