import { Injectable } from '@angular/core';
import { ILoginSuccessData, IRefreshSuccessData } from '../models';
import { IUserMe } from 'src/app/_core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private _user: IUserMe;
  private _roles: Array<string>;
  private _permissions: Array<string>;

  constructor() {
    console.log('AuthorizationService created');

    this.doReset();
    if (localStorage && localStorage.getItem('currentUser')) {
      const _user = JSON.parse(localStorage.getItem('currentUser'));
      this._user = {
        id: _user.id,
        name: _user.name,
        email: _user.email,
        phone: _user.phone,
      };
      //
      this._roles = _user.roles;
      this._permissions = _user.permissions;
    }
  }

  public doLogin(data: ILoginSuccessData) {
    const _expires: Date = new Date();
    _expires.setMilliseconds(_expires.getMilliseconds() + data.expires_in * 1000);
    localStorage.setItem('currentUser', JSON.stringify({
      id: +data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      token: data.access_token,
      duration: data.expires_in,
      expires: _expires.toISOString(),
      //
      roles: data.roles,
      permissions: data.permissions,
    }));

    this._user.id = data.id.toString();
    this._user.name = data.name;
    this._user.email = data.email;
    this._user.phone = data.phone;

    this._roles = data.roles;
    this._permissions = data.permissions;
  }

  public doLogout():void {
    localStorage.removeItem('currentUser');
    this.doReset();
  }

  public doRefresh(data: IRefreshSuccessData):void {
    const _expires: Date = new Date();
    _expires.setMilliseconds(_expires.getMilliseconds() + data.expires_in * 1000);
    //
    const _user = JSON.parse(localStorage.getItem('currentUser'));
    //
    _user.token = data.access_token;
    _user.duration = data.expires_in;
    _user.expires = _expires.toISOString();
    //
    _user.roles = data.roles;
    _user.permissions = data.permissions;
    //
    localStorage.setItem('currentUser', JSON.stringify(_user));
  }

  public doUserUpdate(data: IUserMe):void {
    const _user = JSON.parse(localStorage.getItem('currentUser'));
    //
    _user.name = data.name;
    _user.email = data.email;
    _user.phone = data.phone;
    //
    localStorage.setItem('currentUser', JSON.stringify(_user));

    this._user.name = data.name;
    this._user.email = data.email;
    this._user.phone = data.phone;
  }

  private doReset() :void {
    this._user = {
      id: null,
      name: null,
      email: null,
      phone: null,
    };

    this._roles = [];
    this._permissions = [];
  }

  public hasRole(role: string):boolean {
    return this._roles.includes(role);
    //return this._roles.some((item: string) => item === role);
  }

  public hasNotRole(role: string):boolean {
    return !this.hasRole(role);
  }

  public hasAnyRole(roles: string[]) :boolean {
    const _match: string[] = roles.filter((role: string) => this.hasRole(role));
    return _match.length ? true : false;
  }

  public hasPermission(permission: string, status_id?: number): boolean {
    let _value = this._permissions.includes(permission);

    if (permission == 'order.update' &&  typeof(status_id) != 'undefined') {
      if (this.hasPermission('order.magic_wand')) {
        console.log('order.magic_wand', permission, status_id)
        _value = true;
      } else if ([1].includes(status_id)) {
        console.log('draft', permission, status_id)
        _value = true;
      } else {
        console.log('else', permission, status_id)
        _value = false;
      }
    }

    return _value;
  }

  public hasNotPermission(permission: string, status_id?: number): boolean {
    return !this.hasPermission(permission, status_id);
  }

  public isOwnedBy(user_id: string):boolean {
    return user_id.toString() === this._user.id.toString();
  }

  public isSalesOnly() :boolean {
    return !this._roles.some((item: string) => item !== 'sales' && item !== 'sales-jr');
  }

}
