import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  public constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.doCheck(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.doCheck(route, state);
  }

  private doCheck(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    /**
     * Not logged in
     */
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const _now: Date = new Date();
    const _exp: Date = new Date(currentUser.expires);

    /**
     * Token expired
     */
    if (_now > _exp) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    /**
     * Roles and permission check
     */
    // console.log(this.router);

    /**
     * logged in and token not expired
     */
    return true;

  }
}
