import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_core';

import { AuthenticationModule } from './authentication/authentication.module';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule),
        canActivateChild: [AuthGuard]
    },
    // otherwise go to 404
    // @TODO: Implement 404
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), AuthenticationModule],
    exports: [RouterModule]
})

export class AppRoutingModule { }
