import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
//
import * as fromAuhentication from '../authentication/_state/authentication.reducer';
import * as fromUserInterface from '../_core/_state/user-interface/user-interface.reducer';

export interface State {
  authentication: fromAuhentication.State;
  ui: fromUserInterface.State;
}

export const reducers: ActionReducerMap<State> = {
  authentication: fromAuhentication.reducer,
  ui: fromUserInterface.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
