import { Action } from '@ngrx/store';

export enum UserInterfaceActionTypes {
  SidebarOpen = '[UserInterface] Sidebar Open',
  SidebarClose = '[UserInterface] Sidebar Close',
  SidebarToggle = '[UserInterface] Sidebar Toggle',
}

export class UserInterfaceSidebarOpen implements Action {
  readonly type = UserInterfaceActionTypes.SidebarOpen;
}

export class UserInterfaceSidebarClose implements Action {
  readonly type = UserInterfaceActionTypes.SidebarClose;
}

export class UserInterfaceSidebarToggle implements Action {
  readonly type = UserInterfaceActionTypes.SidebarToggle;
}

export type UserInterfaceActions =
  UserInterfaceSidebarOpen
  | UserInterfaceSidebarClose
  | UserInterfaceSidebarToggle
  ;
