import { Action } from '@ngrx/store';
import {
  ILoginRequest,
  ILoginSuccess,
  ILogoutRequest,
  ILogoutSuccess,
  IRecoverSuccess,
  IRecoverRequest,
  IRefreshRequest,
  IRefreshSuccess,
  IResetRequest,
  IResetSuccess
} from '../models';

export enum ActionTypes {
  // Reset State
  ResetState = '[Authentication] Authentication Reset State',

  // Login
  LoginRequest = '[Authentication] Authentication Login Request',
  LoginSuccess = '[Authentication] Authentication Login Success',
  LoginFailure = '[Authentication] Authentication Login Failure',

  // Logout
  LogoutRequest = '[Authentication] Authentication Logout Request',
  LogoutSuccess = '[Authentication] Authentication Logout Success',
  LogoutFailure = '[Authentication] Authentication Logout Failure',

  // Recover
  RecoverRequest = '[Authentication] Authentication Recover Request',
  RecoverSuccess = '[Authentication] Authentication Recover Success',
  RecoverFailure = '[Authentication] Authentication Recover Failure',

  // Refresh
  RefreshRequest = '[Authentication] Authentication Refresh Request',
  RefreshSuccess = '[Authentication] Authentication Refresh Success',
  RefreshFailure = '[Authentication] Authentication Refresh Failure',

  // Reset
  ResetRequest = '[Authentication] Authentication Reset Request',
  ResetSuccess = '[Authentication] Authentication Reset Success',
  ResetFailure = '[Authentication] Authentication Reset Failure',
}

/**
 * Reset State
 */
export class ResetState implements Action {
  public readonly type = ActionTypes.ResetState;
  constructor() { }
}

/**
 * Login
 */
export class LoginRequest implements Action {
  readonly type = ActionTypes.LoginRequest;
  constructor(public payload: { data: ILoginRequest }) { }
}

export class LoginSuccess implements Action {
  public readonly type = ActionTypes.LoginSuccess;
  constructor(public payload: { response: ILoginSuccess }) { }
}

export class LoginFailure implements Action {
  public readonly type = ActionTypes.LoginFailure;
  constructor(public payload: { error: Error }) { }
}

/**
 * Logout
 */
export class LogoutRequest implements Action {
  readonly type = ActionTypes.LogoutRequest;
  constructor(public payload: { data: ILogoutRequest }) { }
}

export class LogoutSuccess implements Action {
  public readonly type = ActionTypes.LogoutSuccess;
  constructor(public payload: { response: ILogoutSuccess }) { }
}

export class LogoutFailure implements Action {
  public readonly type = ActionTypes.LogoutFailure;
  constructor(public payload: { error: Error }) { }
}

/**
 * Recover
 */
export class RecoverRequest implements Action {
  readonly type = ActionTypes.RecoverRequest;
  constructor(public payload: { data: IRecoverRequest }) { }
}

export class RecoverSuccess implements Action {
  public readonly type = ActionTypes.RecoverSuccess;
  constructor(public payload: { response: IRecoverSuccess }) { }
}

export class RecoverFailure implements Action {
  public readonly type = ActionTypes.RecoverFailure;
  constructor(public payload: { error: Error }) { }
}

/**
 * Refresh
 */
export class RefreshRequest implements Action {
  readonly type = ActionTypes.RefreshRequest;
  constructor(public payload: { data: IRefreshRequest }) { }
}

export class RefreshSuccess implements Action {
  public readonly type = ActionTypes.RefreshSuccess;
  constructor(public payload: { response: IRefreshSuccess }) { }
}

export class RefreshFailure implements Action {
  public readonly type = ActionTypes.RefreshFailure;
  constructor(public payload: { error: Error }) { }
}

/**
 * Reset
 */
export class ResetRequest implements Action {
  readonly type = ActionTypes.ResetRequest;
  constructor(public payload: { data: IResetRequest }) { }
}

export class ResetSuccess implements Action {
  public readonly type = ActionTypes.ResetSuccess;
  constructor(public payload: { response: IResetSuccess }) { }
}

export class ResetFailure implements Action {
  public readonly type = ActionTypes.ResetFailure;
  constructor(public payload: { error: Error }) { }
}

export type Actions =
  // Reset State
  ResetState

  // Login
  | LoginRequest
  | LoginSuccess
  | LoginFailure

  // Logout
  | LogoutRequest
  | LogoutSuccess
  | LogoutFailure

  // Recover
  | RecoverRequest
  | RecoverSuccess
  | RecoverFailure

  // Refresh
  | RefreshRequest
  | RefreshSuccess
  | RefreshFailure

  // Reset
  | ResetRequest
  | ResetSuccess
  | ResetFailure
  ;
