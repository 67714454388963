import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromAuthentication from './_state/authentication.reducer';
import { AuthenticationEffects } from './_state/authentication.effects';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationHeaderComponent, AuthenticationFooterComponent, AuthenticationLoginComponent, AuthenticationLogoutComponent, AuthenticationPasswordRecoverComponent, AuthenticationPasswordResetComponent } from './components';


@NgModule({
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    //
    ReactiveFormsModule,
    //
    StoreModule.forFeature('authentication', fromAuthentication.reducer),
    EffectsModule.forFeature([AuthenticationEffects])
  ],
  declarations: [
    AuthenticationHeaderComponent,
    AuthenticationFooterComponent,
    AuthenticationLoginComponent,
    AuthenticationLogoutComponent,
    AuthenticationPasswordRecoverComponent,
    AuthenticationPasswordResetComponent
  ]
})
export class AuthenticationModule { }
