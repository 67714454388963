import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
//
import { AlertService, IResponse } from '../../_core';
//
import * as fromActions from './authentication.actions';
import { AuthenticationService, AuthorizationService } from '../services';

import { ILoginSuccess, ILoginSuccessData } from '../models';
import { ILogoutSuccess } from '../models';
import { IRecoverSuccess } from '../models';
import { IRefreshSuccess, IRefreshSuccessData } from '../models';
import { IResetSuccess } from '../models';

@Injectable()
export class AuthenticationEffects {

  /**
   * Login
   */
  public loginRequest$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.LoginRequest),
    switchMap((action: fromActions.LoginRequest) => this._authentication_service.login(action.payload.data).pipe(
      map((response: ILoginSuccess) => new fromActions.LoginSuccess({ response })),
      catchError((error: Error) => {
        this._authorization_service.doLogout();
        console.log(error);
        this._alert.error('Login error', error.toString());
        return of(new fromActions.LoginFailure({ error }));
      })
    ))
  ));

  public redirectToDashboard$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.LoginSuccess),
    map((action: fromActions.LoginSuccess) => action.payload.response.data),
    switchMap((data: ILoginSuccessData) => {
      const _returnUrl: string = this._route.snapshot.queryParams['returnUrl'] || '/';
      void this._router.navigate([_returnUrl]);
      return of(data);
    }),
  ), { dispatch: false });

  public localStorage$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.LoginSuccess),
    map((action: fromActions.LoginSuccess) => action.payload.response.data),
    switchMap((data: ILoginSuccessData) => {
      this._authorization_service.doLogin(data);
      return of(data);
    }),
  ), { dispatch: false });


  /**
   * Logout
   */
  public logoutRequest$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.LogoutRequest),
    switchMap((action: fromActions.LogoutRequest) => this._authentication_service.logout(action.payload.data).pipe(
      map((response: ILogoutSuccess) => new fromActions.LogoutSuccess({ response })),
      catchError((error: Error) => {
        this._authorization_service.doLogout();
        console.log(error);
        this._alert.error('Logout error', error.toString());
        return of(new fromActions.LogoutFailure({ error }));
      })
    ))
  ));

  public logoutSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.LogoutSuccess),
    map((action: fromActions.LogoutSuccess) => action.payload.response.data),
    switchMap((data: ILogoutSuccess) => {
      this._authorization_service.doLogout();
      return of(data);
    }),
  ), { dispatch: false });


  /**
   * Recover
   */
  public recoverRequest$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.RecoverRequest),
    switchMap((action: fromActions.RecoverRequest) => this._authentication_service.recover(action.payload.data).pipe(
      map((response: IRecoverSuccess) => new fromActions.RecoverSuccess({ response })),
      catchError((error: Error) => {
        this._authorization_service.doLogout();
        console.log(error);
        this._alert.error('Recover error', error.toString());
        return of(new fromActions.RecoverFailure({ error }));
      })
    ))
  ));

  public recoverSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.RecoverSuccess),
    map((action: fromActions.RecoverSuccess) => action.payload.response.data),
    switchMap((data: IRecoverSuccess) => {
      this._authorization_service.doLogout();
      return of(data);
    }),
  ), { dispatch: false });


  /**
   * Refresh
   */
  public refreshRequest$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.RefreshRequest),
    switchMap((action: fromActions.RefreshRequest) => this._authentication_service.refresh().pipe(
      map((response: IRefreshSuccess) => new fromActions.RefreshSuccess({ response })),
      catchError((error: Error) => {
        this._authorization_service.doLogout();
        console.log(error);
        this._alert.error('Refresh error', error.toString());
        return of(new fromActions.RefreshFailure({ error }));
      })
    ))
  ));

  public refreshSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.RefreshSuccess),
    map((action: fromActions.RefreshSuccess) => action.payload.response.data),
    switchMap((data: IRefreshSuccessData) => {
      this._authorization_service.doRefresh(data);
      return of(data);
    }),
  ), { dispatch: false });


  /**
   * Reset
   */
  public resetRequest$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.ResetRequest),
    switchMap((action: fromActions.ResetRequest) => this._authentication_service.reset(action.payload.data).pipe(
      map((response: IResetSuccess) => new fromActions.ResetSuccess({ response })),
      catchError((error: Error) => {
        this._authorization_service.doLogout();
        console.log(error);
        this._alert.error('Reset error', error.toString());
        return of(new fromActions.ResetFailure({ error }));
      })
    ))
  ));

  public resetSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(fromActions.ActionTypes.RecoverSuccess),
    map((action: fromActions.RecoverSuccess) => action.payload.response.data),
    switchMap((data: IRecoverSuccess) => {
      this._authorization_service.doLogout();
      return of(data);
    }),
  ), { dispatch: false });


  constructor(
    private _alert: AlertService,
    private _actions$: Actions,
    private _authentication_service: AuthenticationService,
    private _authorization_service: AuthorizationService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) { }
}
