<div class="authentication-login">
  <div class="container-fluid">

    <div class="card">
      <div class="card-body">

        <app-authentication-header></app-authentication-header>

        <form *ngIf="login==false" [formGroup]="mainForm" (ngSubmit)="doSubmit()">
          <div class="form-group">
            <label class="required" for="frm-email">Email</label>
            <input type="email" formControlName="email" id="frm-email" class="form-control" />
          </div>
          <div class="form-group">
            <label class="required" for="frm-password">Password</label>
            <input type="password" formControlName="password" id="frm-password" class="form-control" />
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" [disabled]="mainForm.invalid || loading">login</button>
          </div>
        </form>

        <div class="small mt-4">
          <a [routerLink]="['/recover']">Password dimenticata? Recuperala qui.</a>
        </div>

        <div *ngIf="login==true" class="alert alert-primary small mt-4" role="alert">
          Login effettuato con successo.
        </div>

        <p class="small mt-4">Form Status: {{ mainForm.status }}</p>

      </div>
    </div>

    <app-authentication-footer></app-authentication-footer>

  </div>
</div>