import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Misc models
import { environment } from '../../../environments/environment';
import { IResponse } from '../../_core';
import { ILoginRequest, ILogoutRequest, IRecoverRequest, IRefreshRequest, IResetRequest } from '../models';

// import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public _api_endpoint = {
        login: environment.apiUrl + 'login',
        logout: environment.apiUrl + 'logout',
        refresh: environment.apiUrl + 'refresh',
        //
        recover: environment.apiUrl + 'recover',
        reset: environment.apiUrl + 'reset',
    };

    public constructor(private _http: HttpClient) { }

    public login(payload: ILoginRequest): Observable<IResponse> {
        return this._http.post(this._api_endpoint.login, payload) as Observable<IResponse>;
    }

    public logout(payload: ILogoutRequest): Observable<IResponse> {
        return this._http.post(this._api_endpoint.logout, payload) as Observable<IResponse>;
    }

    public recover(payload: IRecoverRequest): Observable<IResponse> {
        return this._http.post(this._api_endpoint.recover, payload) as Observable<IResponse>;
    }

    public refresh(): Observable<IResponse> {
        const payload = {};
        return this._http.post(this._api_endpoint.refresh, payload) as Observable<IResponse>;
    }

    public reset(payload: IResetRequest): Observable<IResponse> {
        return this._http.post(this._api_endpoint.reset, payload) as Observable<IResponse>;
    }
}
