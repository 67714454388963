import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
//
import { ILogoutRequest } from '../../models';
import * as fromState from '../../_state/authentication.reducer';
import * as fromActions from '../../_state/authentication.actions';


@Component({
  selector: 'app-authentication-logout',
  templateUrl: './authentication-logout.component.html',
  styleUrls: ['./authentication-logout.component.scss']
})
export class AuthenticationLogoutComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public logout: boolean;
  private loading$: Subscription;
  private logout$: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromState.State>,
  ) {
    /**
     * Dispatch events
     */
    this._store.dispatch(new fromActions.ResetState());
 }

  ngOnInit() {
    /**
     * Store bindings
     */
    this.loading$ = this._store.select(fromState.selectLoading).subscribe((loading: boolean) => this.loading = loading);
    this.logout$ = this._store.select(fromState.selectLogout).subscribe((logout: boolean) => this.logout = logout);

    /**
     * Dispatch events
     */
    const data: ILogoutRequest = {};
    this._store.dispatch(new fromActions.LogoutRequest({ data }));
}

  /**
   * Destroy stuffs
   */
  public ngOnDestroy() {
    this.loading$.unsubscribe();
    this.logout$.unsubscribe();
  }

}
