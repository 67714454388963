import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
//
import { ILoginRequest } from '../../models';
import * as fromState from '../../_state/authentication.reducer';
import * as fromActions from '../../_state/authentication.actions';

@Component({
  selector: 'app-authentication-login',
  templateUrl: './authentication-login.component.html',
  styleUrls: ['./authentication-login.component.scss']
})
export class AuthenticationLoginComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public login: boolean;
  private loading$: Subscription;
  private login$: Subscription;

  public mainForm: FormGroup;

  public constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromState.State>,
    private _fb: FormBuilder,
  ) {
    /**
     * Dispatch events
     */
    this._store.dispatch(new fromActions.ResetState());

    /**
     * Form setup & validation
     */
    this.mainForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: [false],
    });
   }

  public ngOnInit() {
    /**
     * Store bindings
     */
    this.loading$ = this._store.select(fromState.selectLoading).subscribe((loading: boolean) => this.loading = loading);
    this.login$ = this._store.select(fromState.selectLogin).subscribe((login: boolean) => this.login = login);
  }

  /**
   * Getter for easy access form fields
   */
  public get f() {
    return this.mainForm.controls;
  }

  /**
   * Main Form submission
   */
  public doSubmit() {
    if (this.mainForm.invalid) {
      return false;
    }

    const data: ILoginRequest = this.mainForm.value;
    this._store.dispatch(new fromActions.LoginRequest({ data }));
  }

  /**
   * Destroy stuffs
   */
  public ngOnDestroy() {
    this.loading$.unsubscribe();
    this.login$.unsubscribe();
  }

}
