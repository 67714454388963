import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationLoginComponent, AuthenticationLogoutComponent, AuthenticationPasswordRecoverComponent, AuthenticationPasswordResetComponent } from './components';

const aRoutes: Routes = [
  { path: 'login', component: AuthenticationLoginComponent, pathMatch: 'full' },
  { path: 'logout', component: AuthenticationLogoutComponent, pathMatch: 'full' },
  { path: 'recover', component: AuthenticationPasswordRecoverComponent, pathMatch: 'full' },
  { path: 'reset', component: AuthenticationPasswordResetComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(aRoutes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
