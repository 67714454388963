import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Actions, ActionTypes } from './authentication.actions';

export interface State {
  loading: boolean;
  login: boolean;
  logout: boolean;
  recover: boolean;
  reset: boolean;
}

export const initialState: State = {
  loading: false,
  login: false,
  logout: false,
  recover: false,
  reset: false,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    /**
     * Reset State
     */
    case ActionTypes.ResetState:
      return {
        ...state,
        loading: false,
        login: false,
        logout: false,
        recover: false,
        reset: false,
      };

    /**
     * Login
     */
    case ActionTypes.LoginRequest:
      return {
        ...state,
        loading: true,
        login: false,
      };

    case ActionTypes.LoginSuccess:
      return {
        ...state,
        loading: false,
        login: true,
      };

    case ActionTypes.LoginFailure:
      return {
        ...state,
        loading: false,
        login: false,
      };


    /**
     * Logout
     */
    case ActionTypes.LogoutRequest:
      return {
        ...state,
        loading: true,
        logout: false,
      };

    case ActionTypes.LogoutSuccess:
      return {
        ...state,
        loading: false,
        logout: true,
      };

    case ActionTypes.LogoutFailure:
      return {
        ...state,
        loading: false,
        logout: false,
      };


    /**
     * Recover
     */
    case ActionTypes.RecoverRequest:
      return {
        ...state,
        loading: true,
        recover: false,
      };

    case ActionTypes.RecoverSuccess:
      return {
        ...state,
        loading: false,
        recover: true,
      };

    case ActionTypes.RecoverFailure:
      return {
        ...state,
        loading: false,
        recover: false,
      };


    /**
     * Refresh
     */
    case ActionTypes.RefreshRequest:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.RefreshSuccess:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.RefreshFailure:
      return {
        ...state,
        loading: false,
      };

    /**
     * Reset
     */
    case ActionTypes.ResetRequest:
      return {
        ...state,
        loading: true,
        reset: false,
      };

    case ActionTypes.ResetSuccess:
      return {
        ...state,
        loading: false,
        reset: true,
      };

    case ActionTypes.ResetFailure:
      return {
        ...state,
        loading: false,
        reset: false,
      };


    /**
     * Default
     */
    default:
      return state;
  }
}


export const selectState = createFeatureSelector<State>('authentication');

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading
);

export const selectLogin = createSelector(
  selectState,
  (state: State) => state.login
);

export const selectLogout = createSelector(
  selectState,
  (state: State) => state.logout
);

export const selectRecover = createSelector(
  selectState,
  (state: State) => state.recover
);

export const selectReset = createSelector(
  selectState,
  (state: State) => state.reset
);
